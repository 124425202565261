<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
      <CourseGroupForm v-if="pageData" :page-data="pageData" />
    </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header.vue';
import CourseGroupForm from '@/components/course/CourseGroupForm.vue';
export default Vue.extend({
  components: {
    Layout,
    PageHeader,
    CourseGroupForm
  },
  data() {
    return {
      title: 'Course Group Add',
      items: [
        {
          text: 'Course',
          href: '/'
        },
        {
          text: 'Group',
          href: '/'
        },
        {
          text: 'Group Add',
          active: true
        }
      ],
      pageData: null
    };
  },
  created() {
    this.$store
      .dispatch('course/getCourseGroupInfo', {
        id: this.$route.params.idx
      })
      .then(res => {
        console.log(res);
        if (res.result) {
          if (res.list === null) this.pageData = {};
          else this.pageData = res.list;
        }
      });
  }
});
</script>
